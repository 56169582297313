// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {MaskType, Popover} from 'pages/components/Popover';

import {UserCard} from './UserCard';
import {PopoverProps} from './UserCard.types';

export function UserCardPopover({onClose}: PopoverProps) {
  return (
    <Popover onClose={onClose} maskType={MaskType.Transparent}>
      <UserCard />
    </Popover>
  );
}
