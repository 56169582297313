// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {checkOrder, getUserSubscription} from 'api/payment';
import * as apiServer from 'api/server';
import {combine} from 'components/Combine';
import {clearOutTradeNo, getOutTradeNo} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {getQueryStringParameters} from 'lib/common';
import {isSubscription} from 'modules/payment/services';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {reportSubscriptionToGoogle} from 'utils/gtag';

import {creditOptions} from '../constData';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import {PaymentResult} from './PaymentResult';
import {CheckState, HookParams, HookReturn} from './PaymentResult.types';

export function useHook({isSuccess}: HookParams): HookReturn {
  const [checkState, setCheckState] = useState<CheckState>('CHECKING');
  const productInfo: HookReturn['productInfo'] = useMemo(() => {
    const params = getQueryStringParameters(location.href);
    const is_subscription = isSubscription(Number(params.productId));
    const payCreditItem =
      !is_subscription &&
      creditOptions.find(
        item => Number(item.productId) === Number(params.productId)
      );
    return {
      is_subscription,
      level:
        (is_subscription && getLevelByProductId(Number(params.productId))) ||
        undefined,
      type:
        (is_subscription && getTypeByProductId(Number(params.productId))) ||
        undefined,
      productId: Number(params.productId),
      payCredit: (payCreditItem && payCreditItem.credit) || undefined,
    };
  }, []);
  const outTradeNo = useMemo(() => getOutTradeNo(), []);
  const {userInfo, updateUserInfo} = useUserContext();

  const checkOrderSuccess = useCallback(async () => {
    if (!outTradeNo) return setCheckState('FAIL');
    const {data} = await checkOrder(outTradeNo);

    // 支付成功页面并且订单编号验证成功，则发送转化统计
    if (isSuccess && data) {
      reportSubscriptionToGoogle(outTradeNo);
    }

    if ((isSuccess && data) || (!isSuccess && !data)) {
      setCheckState('SUCCESS');
      apiServer
        .getAccountDetails()
        .then((userCreditData: apiServer.GetAccountDetailsResponseType) => {
          updateUserInfo({
            credit: userCreditData.creditWalletAmount,
            planCreditAmount: userCreditData.planCreditAmount,
            creditPackAmount: userCreditData.creditPackAmount,
          });
        });
      if (productInfo.is_subscription) {
        const {
          data: {subscriptionDetail},
        } = await getUserSubscription(userInfo.userId);
        if (subscriptionDetail) {
          updateUserInfo({
            // expireDate: subscriptionDetail.periodEnd,
            autoRenewal: Boolean(subscriptionDetail.autoRenewal),
          });
        }
      }
    } else if ((isSuccess && !data) || (!isSuccess && data)) {
      setCheckState('FAIL');
    }
    clearOutTradeNo();
  }, [
    isSuccess,
    outTradeNo,
    productInfo.is_subscription,
    updateUserInfo,
    userInfo.userId,
  ]);

  useEffect(() => {
    checkOrderSuccess();
  }, [checkOrderSuccess]);

  return {checkState, isSuccess, productInfo, userInfo};
}

export const PaymentResultContainer = combine(useHook, ['isSuccess'])(
  PaymentResult
);
