// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CompleteIcon} from 'assets/svg/fill/Complete.svg';
import {ReactComponent as DeleteIcon} from 'assets/svg/outline/Delete.svg';
import {ReactComponent as WarningIcon} from 'assets/svg/outline/Warning.svg';
import classNames from 'classnames';
import {useMessageContext} from 'contexts/MessageContext';

import styles from './MessageBox.module.scss';

export function MessageBox() {
  const {message} = useMessageContext();
  return (
    <>
      {message && (
        <div className={classNames(styles.container, 'label-m')}>
          {message.type === 'SUCCESS' && (
            <CompleteIcon className={classNames(styles.icon, styles.success)} />
          )}
          {message.type === 'WARNING' && (
            <WarningIcon className={classNames(styles.icon, styles.warning)} />
          )}
          {message.type === 'ERROR' && (
            <DeleteIcon className={classNames(styles.icon, styles.error)} />
          )}
          <span className={classNames('label-m', styles.content)}>
            {message.content}
          </span>
        </div>
      )}
    </>
  );
}
