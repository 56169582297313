// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import styles from './Loading.module.scss';
import {Props} from './Loading.types';

export function Loading({size, borderWidth}: Props) {
  return (
    <div
      className={styles['rotate-circle']}
      style={{
        width: size + 'px',
        height: size + 'px',
        borderWidth: borderWidth + 'px',
      }}
    />
  );
}
