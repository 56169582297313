// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LogoutIcon} from 'assets/svg/outline/LogOut.svg';
import classNames from 'classnames';
import {clearUserToken} from 'contexts/localStorage';
import {useUserContext} from 'contexts/UserContext';
import {ProductIdEnum} from 'modules/payment/types';
import {convertUTCToLocaleDateString} from 'pages/common/date';

import {billingTipsMap, subscriptionMap} from '../constData';
import {getLevelByProductId, getTypeByProductId} from '../Plan.container';
import styles from './UserCard.module.scss';

export function UserCard() {
  const {userInfo, activeProductId, subscriptionProductId} = useUserContext();

  return (
    <>
      <div className={classNames('glass-edge', styles.container)}>
        <div className={styles.header}>
          <img src={userInfo.portrait} className={styles.avatar} />
          <p className={styles.username}>{userInfo.userName}</p>
          <LogoutIcon
            className={classNames('cursor-pointer', styles.logout)}
            onClick={() => {
              clearUserToken();
              window.location.href = process.env
                .REACT_APP_HOMEPAGE_URL as string;
            }}
          />
        </div>
        <p className={styles.divider} />
        <dl className={styles.info}>
          <dt>{subscriptionMap[getLevelByProductId(activeProductId)]}</dt>
          <dd>
            <span className={classNames(styles.label, 'body-m')}>
              Remaining Credits
            </span>
            <span>
              {userInfo.credit + userInfo.creditPackAmount} /{' '}
              {userInfo.planCreditAmount}
            </span>
          </dd>
          {activeProductId !== ProductIdEnum.FREE && userInfo.expireDate && (
            <dd>
              {userInfo.autoRenewal && (
                <span className={classNames(styles.label, 'body-m')}>
                  Renewal Date{' '}
                </span>
              )}
              {!userInfo.autoRenewal && (
                <span className={classNames(styles.label, 'body-m')}>
                  Expire Date{' '}
                </span>
              )}
              <span>
                {convertUTCToLocaleDateString(
                  new Date(Number(userInfo.expireDate))
                )}
              </span>
            </dd>
          )}
        </dl>
        {typeof subscriptionProductId === 'number' &&
          subscriptionProductId !== ProductIdEnum.FREE &&
          activeProductId !== subscriptionProductId && (
            <p className={classNames('body-m', styles.next_plan)}>
              {
                billingTipsMap[getLevelByProductId(subscriptionProductId)][
                  getTypeByProductId(subscriptionProductId)
                ]
              }
            </p>
          )}
      </div>
    </>
  );
}
