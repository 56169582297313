// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useRef, useState} from 'react';

import styles from './Popover.module.scss';
import {MaskType, Props} from './Popover.types';

export function Popover({
  children,
  onClose,
  maskType = MaskType.Transparent,
}: Props) {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isClickOutside, setIsClickOutside] = useState(false);

  return (
    <>
      <div className={styles.popover} ref={popoverRef}>
        {children}
      </div>
      <div
        className={classNames(styles['popover-mask'], styles[maskType])}
        onMouseDown={() => setIsClickOutside(true)}
        onClick={() => {
          if (isClickOutside) {
            onClose();
            setIsClickOutside(false);
          }
        }}
      ></div>
    </>
  );
}
