import {UserInfo} from 'api/server';

export type TypeStateEnum = 'Monthly' | 'Yearly';

export type LevelEnum = 'Free' | 'Standard' | 'Pro';

export type UseHookReturn = {
  typeState: TypeStateEnum | undefined;
  setTypeState: (type: TypeStateEnum) => void;
  currentPlanInfo: {
    type: TypeStateEnum;
    level: LevelEnum;
    nextLevel: LevelEnum;
    nextType: TypeStateEnum;
  } | null;
  selectCreditToBuy: number | undefined;
  onChangeSelectCreditToBuy: (value: number) => void;
  onAction: (action: ActionType, productId: number) => void;
  onDowngradeConfirm: () => void;
  onUpgradeConfirm: () => void;
  downgradeProductId: number | null;
  upgradeProductId: number | null;
  clearActionProductId: (
    type: Extract<ActionType, 'Downgrade' | 'Upgrade'>
  ) => void;
  userInfo: UserInfo;
  renewalDate: string;
  isOnAction: boolean;
};

export type ButtonInfoProps = {
  userLevel: LevelEnum;
  userType: TypeStateEnum;
  currentLevel: LevelEnum;
  currentType: TypeStateEnum;
};
export type ButtonInfoReturn = {
  type: 'ghost' | 'button';
  children: string;
  theme?: 'default';
};
export type PlanItem = {
  title: LevelEnum;
  flag?: string;
  perMonthCharge: number;
  perMonthChargeOfMonthly?: number;
  creditsPerMonth: number;
  perYearlyCharge?: number;
  creditsPerMonthText: string;
  creditsPerMonthTextIsLocked: boolean;
  features: {
    text: string | JSX.Element;
    className?: string;
  }[][];
  productId: number;
};
export type PlanPageProps = UseHookReturn;
export type PlanListProps = {
  Monthly: PlanItem[];
  Yearly: PlanItem[];
};
export type ActionType = 'Upgrade' | 'Subscription' | 'Downgrade';
export enum ProductIdEnum {
  FREE = 0,
  STANDARD_MONTHLY = 3,
  STANDARD_YEARLY = 4,
  PRO_MONTHLY = 5,
  PRO_YEARLY = 6,
}
export type FeaturesOfLevelMapType = {
  [key in LevelEnum]: {
    text: string | JSX.Element;
    className?: string;
    isIcon?: boolean;
  }[][];
};
