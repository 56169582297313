// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DotIcon} from 'assets/svg/fill/Dot.svg';
import {ReactComponent as CreditIcon} from 'assets/svg/outline/CreditIcon.svg';
import {ReactComponent as WarningIcon} from 'assets/svg/outline/Info.svg';
import {ReactComponent as LockIcon} from 'assets/svg/outline/Lock.svg';
import {ReactComponent as RightIcon} from 'assets/svg/outline/Tick.svg';
import classNames from 'classnames';
import {Button} from 'pages/components/Button';
import {Popover} from 'pages/components/PopoverNew';
import {Toast} from 'pages/components/Toast';
import {ToggleSwitch} from 'pages/components/ToggleSwitch';
import {Outlet} from 'react-router-dom';

import {
  AnimatorFeaturesTips,
  billingTipsMap,
  creditOptions,
  creditsPerMonthByLevel,
  PerMonthChargeMap,
  subscriptionMap,
  ToggleOptions,
} from './constData';
import {Footer} from './Footer';
import {Header} from './Header';
import {getButtonProps} from './Plan.container';
import styles from './Plan.module.scss';
import {
  FeaturesOfLevelMapType,
  PlanListProps,
  PlanPageProps,
  TypeStateEnum,
} from './Plan.types';

export const FeaturesOfLevelMap: FeaturesOfLevelMapType = {
  Free: [
    [
      {
        text: '20,000 characters prompt',
      },
    ],
    [
      {
        text: '16 scenes per project maximum',
      },
    ],
    [
      {
        text: '5 visual styles',
      },
    ],
    [
      {
        text: '100MB asset upload',
      },
    ],
    [
      {
        text: 'Watermark free',
      },
      {
        text: '(limited time offer)',
        className: styles.thinText,
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [
      {
        text: 'Unlimited exports',
      },
    ],
    [{text: 'General commercial term'}],
    [{text: '1080p'}],
    [
      {
        text: 'All',
      },
      {
        text: ' Mootion Animator',
        className: styles.strongText,
      },
      {
        text: ' features',
      },
      {
        text: (
          <Popover
            triggerElement={<WarningIcon className={styles.tipIcon} />}
            trigger="hover"
            direction="bottom"
            distance={10}
            content={
              <div className={styles.tipContent}>
                {AnimatorFeaturesTips.Free.map((tip, index) => {
                  return (
                    <div className={styles.tipItem} key={index}>
                      <span className={styles.dotIcon}>
                        <DotIcon />
                      </span>
                      <span className={styles.textWrap}>{tip}</span>
                    </div>
                  );
                })}
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
  ],
  Standard: [
    [
      {
        text: '20,000 characters prompt',
      },
    ],
    [
      {
        text: '16 scenes per project maximum',
      },
    ],
    [
      {
        text: '10+ ',
        className: styles.colorText,
      },
      {
        text: 'visual styles',
      },
    ],
    [
      {
        text: '10GB',
        className: styles.colorText,
      },
      {
        text: ' asset upload',
      },
    ],
    [
      {
        text: 'Watermark free',
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [
      {
        text: 'Unlimited exports',
      },
    ],
    [{text: 'General commercial term'}],
    [{text: '1080p'}],
    [
      {
        text: 'All',
      },
      {
        text: ' Mootion Animator',
        className: styles.strongText,
      },
      {
        text: ' features',
      },
      {
        text: (
          <Popover
            triggerElement={<WarningIcon className={styles.tipIcon} />}
            trigger="hover"
            direction="bottom"
            distance={10}
            content={
              <div className={styles.tipContent}>
                {AnimatorFeaturesTips.Standard.map((tip, index) => {
                  return (
                    <div className={styles.tipItem} key={index}>
                      <span className={styles.dotIcon}>
                        <DotIcon />
                      </span>
                      <span className={styles.textWrap}>{tip}</span>
                    </div>
                  );
                })}
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
  ],
  Pro: [
    [
      {
        text: '50,000',
        className: styles.colorText,
      },
      {
        text: ' characters prompt',
      },
    ],
    [
      {
        text: '32',
        className: styles.colorText,
      },
      {
        text: ' scenes per project maximum',
      },
    ],
    [
      {
        text: '10+',
        className: styles.colorText,
      },
      {
        text: ' visual styles',
      },
    ],
    [
      {
        text: '100GB',
        className: styles.colorText,
      },
      {
        text: ' asset upload',
      },
    ],
    [
      {
        text: 'Watermark free',
      },
    ],
    [
      {
        text: 'Precise pose & motion control',
      },
    ],
    [
      {
        text: 'Unlimited exports',
      },
    ],
    [{text: 'General commercial term'}],
    [{text: '1080p'}],
    [
      {
        text: 'All',
      },
      {
        text: ' Mootion Animator',
        className: styles.strongText,
      },
      {
        text: ' features',
      },
      {
        text: (
          <Popover
            triggerElement={<WarningIcon className={styles.tipIcon} />}
            trigger="hover"
            direction="left"
            distance={10}
            content={
              <div className={styles.tipContent} style={{width: '276px'}}>
                {AnimatorFeaturesTips.Pro.map((tip, index) => {
                  return (
                    <div className={styles.tipItem} key={index}>
                      <span className={styles.dotIcon}>
                        <DotIcon />
                      </span>
                      <span className={styles.textWrap}>{tip}</span>
                    </div>
                  );
                })}
              </div>
            }
          />
        ),
        isIcon: true,
      },
    ],
  ],
};
export const planList: PlanListProps = {
  Monthly: [
    {
      title: 'Free',
      perMonthCharge: PerMonthChargeMap['Monthly']['Free'],
      creditsPerMonth: creditsPerMonthByLevel['Free'],
      creditsPerMonthText: "Can't buy more credits",
      creditsPerMonthTextIsLocked: true,
      features: FeaturesOfLevelMap['Free'],
      productId: 0,
    },
    {
      title: 'Standard',
      flag: 'Popular',
      perMonthCharge: PerMonthChargeMap['Monthly']['Standard'],
      creditsPerMonth: creditsPerMonthByLevel['Standard'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Standard'],
      productId: 3,
    },
    {
      title: 'Pro',
      perMonthCharge: PerMonthChargeMap['Monthly']['Pro'],
      creditsPerMonth: creditsPerMonthByLevel['Pro'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Pro'],
      productId: 5,
    },
  ],
  Yearly: [
    {
      title: 'Free',
      perMonthCharge: PerMonthChargeMap['Yearly']['Free'],
      creditsPerMonth: creditsPerMonthByLevel['Free'],
      creditsPerMonthText: "Can't buy more credits",
      creditsPerMonthTextIsLocked: true,
      features: FeaturesOfLevelMap['Free'],
      productId: 0,
    },
    {
      title: 'Standard',
      flag: 'Popular',
      perMonthCharge: PerMonthChargeMap['Yearly']['Standard'],
      perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Standard'],
      perYearlyCharge: 96,
      creditsPerMonth: creditsPerMonthByLevel['Standard'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Standard'],
      productId: 4,
    },
    {
      title: 'Pro',
      perMonthCharge: PerMonthChargeMap['Yearly']['Pro'],
      perMonthChargeOfMonthly: PerMonthChargeMap['Monthly']['Pro'],
      perYearlyCharge: 384,
      creditsPerMonth: creditsPerMonthByLevel['Pro'],
      creditsPerMonthText: 'Buy more credits as you need',
      creditsPerMonthTextIsLocked: false,
      features: FeaturesOfLevelMap['Pro'],
      productId: 6,
    },
  ],
};

export function PlanPage({
  typeState,
  setTypeState,
  currentPlanInfo,
  selectCreditToBuy,
  onUpgradeConfirm,
  onChangeSelectCreditToBuy,
  onAction,
  downgradeProductId,
  onDowngradeConfirm,
  clearActionProductId,
  upgradeProductId,
  userInfo,
  renewalDate,
  isOnAction,
}: PlanPageProps) {
  if (!typeState || !currentPlanInfo) return null;

  return (
    <div className={styles.pageContainer}>
      <Header />
      <div className={styles.container}>
        <h1 className={styles.containerTitle} id="choosePlan">
          Choose Your Plan
        </h1>
        <ToggleSwitch
          options={ToggleOptions}
          activeIndex={typeState === 'Monthly' ? 0 : 1}
          onToggle={index => {
            setTypeState(ToggleOptions[index].label as TypeStateEnum);
          }}
          className={styles.toggleSwitch}
          totalWidth={330}
        />
        <div className={styles.planOptionWrap}>
          {planList[typeState].map(item => {
            const buttonProps = getButtonProps({
              userLevel: currentPlanInfo.level,
              userType: currentPlanInfo.type,
              currentLevel: item.title,
              currentType: typeState,
            });
            return (
              <div
                className={classNames(styles.planItem, {
                  [styles.active]:
                    item.title === 'Standard' &&
                    currentPlanInfo.level === 'Free',
                })}
                key={item.title}
              >
                <div className={styles.top}>
                  <div className={styles.title}>
                    {item.title}
                    {item.flag && (
                      <div className={styles.flag}>{item.flag}</div>
                    )}
                  </div>
                  <div className={styles.price}>
                    <div className={styles.priceText}>
                      <span className={styles.perMonthCharge}>
                        {item.perMonthCharge === 0
                          ? 'Free forever'
                          : '$' + item.perMonthCharge}
                      </span>
                      {item.title !== 'Free' && (
                        <div className={styles.append}>
                          <span>
                            {typeState === 'Yearly' &&
                            item.perMonthChargeOfMonthly !== undefined ? (
                              <span className={styles.sourcePrice}>
                                ${item.perMonthChargeOfMonthly}
                              </span>
                            ) : null}
                            /month
                          </span>
                          {typeState === 'Yearly' && (
                            <span>
                              Billed yearly as ${item.perYearlyCharge}
                            </span>
                          )}
                          {typeState === 'Monthly' && (
                            <span>Billed monthly</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.creditsInfo}>
                    <div className={styles.creditsPerMonth}>
                      {item.creditsPerMonth} credits/month
                    </div>
                    <div className={styles.tip}>
                      {item.creditsPerMonthTextIsLocked ? <LockIcon /> : null}
                      {item.creditsPerMonthText}
                    </div>
                  </div>
                </div>

                <Button
                  size="large"
                  disabled={isOnAction}
                  className={styles.buttonSection}
                  {...buttonProps}
                  onClick={() => {
                    if (buttonProps.children === 'Current plan') {
                      return;
                    }
                    if (buttonProps.children === 'Upgrade') {
                      if (currentPlanInfo.level === 'Free') {
                        onAction('Subscription', item.productId);
                      } else {
                        onAction('Upgrade', item.productId);
                      }
                    } else {
                      onAction('Downgrade', item.productId);
                    }
                  }}
                />

                <div className={styles.bottomTips}>
                  {FeaturesOfLevelMap[item.title].map((feature, index) => {
                    return (
                      <div className={styles.tipItem} key={index}>
                        <RightIcon className={styles.icon} />
                        <span className={styles.textWrap}>
                          {feature.map((item, index) => {
                            return item.isIcon ? (
                              item.text
                            ) : (
                              <span
                                className={
                                  item.className ? item.className : styles.text
                                }
                                key={index}
                              >
                                {item.text}
                              </span>
                            );
                          })}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.bootomSectionWrap}>
          <BootomSectionOfBilling
            currentPlanInfo={currentPlanInfo}
            typeState={typeState}
            userInfo={userInfo}
            renewalDate={renewalDate}
          />
          <BootomSectionOfCredit
            currentPlanInfo={currentPlanInfo}
            selectCreditToBuy={selectCreditToBuy}
            onChangeSelectCreditToBuy={onChangeSelectCreditToBuy}
            onClickBuyCredits={() => {
              if (!selectCreditToBuy) return;
              onAction('Subscription', selectCreditToBuy);
            }}
          />
        </div>
        <Outlet />
        {(upgradeProductId !== null || downgradeProductId !== null) && (
          <Toast
            title={
              downgradeProductId !== null
                ? 'Are you sure to downgrade'
                : 'Upgrade for more'
            }
            visible={upgradeProductId !== null || downgradeProductId !== null}
            onOk={() => {
              if (downgradeProductId !== null) {
                onDowngradeConfirm();
              } else if (upgradeProductId !== null) {
                onUpgradeConfirm();
              }
            }}
            onCancel={() =>
              clearActionProductId(
                downgradeProductId !== null ? 'Downgrade' : 'Upgrade'
              )
            }
            showCloseBtn={false}
            btnTheme="primary"
            cancelText="Cancel"
            confirmText={
              downgradeProductId !== null
                ? 'Confirm downgrade'
                : 'Confirm upgrade'
            }
            className={styles.downgradeToast}
          >
            {upgradeProductId !== null &&
              'You will be charged and switch to new plan instantly.'}
            {downgradeProductId !== null &&
              'We’ll keep current plan active until the end of this subscription period?'}
          </Toast>
        )}
      </div>
      <Footer />
    </div>
  );
}
function BootomSectionOfBilling({
  currentPlanInfo,
  userInfo,
  renewalDate,
}: {
  currentPlanInfo: PlanPageProps['currentPlanInfo'];
  typeState: PlanPageProps['typeState'];
  userInfo: PlanPageProps['userInfo'];
  renewalDate?: string;
}) {
  if (!currentPlanInfo) return null;
  return (
    <div className={styles.section + ' ' + styles.billing}>
      <div className={styles.title}>Billing & Payment</div>
      <div className={styles.info}>
        <span>Your subscription</span>
        <span className={styles.right}>
          {subscriptionMap[currentPlanInfo.level]}
          {currentPlanInfo.level !== 'Free'
            ? currentPlanInfo.type === 'Monthly'
              ? ' (billed monthly)'
              : ' (billed yearly)'
            : ''}
        </span>
      </div>
      <div className={styles.info}>
        <span>Remaining credits</span>
        <span className={styles.right}>
          <CreditIcon />
          <span className={styles.credit}>
            {userInfo.credit + userInfo.creditPackAmount} /{' '}
            {userInfo.planCreditAmount}
          </span>
        </span>
      </div>
      {renewalDate && (
        <div className={styles.info}>
          <span>Renewal date</span>
          <span className={styles.right}>{renewalDate}</span>
        </div>
      )}
      {currentPlanInfo.nextLevel + currentPlanInfo.nextType !==
        currentPlanInfo.level + currentPlanInfo.type && (
        <div className={styles.tip}>
          {(billingTipsMap[
            currentPlanInfo.nextLevel || currentPlanInfo.level
          ] &&
            billingTipsMap[currentPlanInfo.nextLevel || currentPlanInfo.level][
              currentPlanInfo.nextType || currentPlanInfo.type
            ]) ||
            ''}
        </div>
      )}
    </div>
  );
}
function BootomSectionOfCredit({
  currentPlanInfo,
  selectCreditToBuy,
  onChangeSelectCreditToBuy,
  onClickBuyCredits,
}: {
  currentPlanInfo: PlanPageProps['currentPlanInfo'];
  selectCreditToBuy: PlanPageProps['selectCreditToBuy'];
  onChangeSelectCreditToBuy: PlanPageProps['onChangeSelectCreditToBuy'];
  onClickBuyCredits: () => void;
}) {
  if (!currentPlanInfo) return null;
  return (
    <div className={styles.section + ' ' + styles.credit}>
      <div className={styles.title}>
        <span>Buy more credits</span>
        {currentPlanInfo.level === 'Free' && (
          <Button
            type="link"
            onClick={() => {
              document.querySelector('#choosePlan')?.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            Upgrade to buy
          </Button>
        )}
      </div>
      <div className={styles.info}>
        Only available for paid tiers. Valid through the current and next
        billing cycle.
      </div>
      <div className={styles.creditOption}>
        {creditOptions.map(option => {
          return (
            <div
              className={classNames(styles.creditOptionItem, {
                [styles.active]: selectCreditToBuy === option.productId,
                [styles.disabled]: currentPlanInfo.level === 'Free',
              })}
              onClick={() => onChangeSelectCreditToBuy(option.productId)}
              key={option.credit}
            >
              <span>
                <CreditIcon className={styles.creditOptionItemIcon} />
                {option.credit}
              </span>
              <span>${option.price}</span>
            </div>
          );
        })}
      </div>
      {currentPlanInfo.level !== 'Free' && (
        <div className={styles.creditSectionFooter}>
          {selectCreditToBuy === undefined ? (
            <Button type="button" disabled>
              Select an amount
            </Button>
          ) : (
            <Button type="button" onClick={onClickBuyCredits}>
              Continue to pay
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
