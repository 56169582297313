// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CompleteIcon} from 'assets/svg/fill/Complete.svg';
import {ReactComponent as CreditIcon} from 'assets/svg/outline/CreditIcon.svg';
import {Button} from 'pages/components/Button';
import {PopoverLoading} from 'pages/components/Loading';
import {Toast} from 'pages/components/Toast';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'router';

import styles from './PaymentResult.module.scss';
import {Props} from './PaymentResult.types';

export function PaymentResult({
  checkState,
  isSuccess,
  productInfo,
  userInfo,
}: Props) {
  const navigate = useNavigate();

  const closeDialog = useCallback(() => {
    navigate(PLAN_PAGE_PATH, {replace: true});
  }, [navigate]);

  const paySuccessOrSubscribeSuccess = isSuccess && checkState === 'SUCCESS';
  return (
    <>
      {checkState === 'CHECKING' && (
        <PopoverLoading size={60} borderWidth={4} />
      )}
      {checkState !== 'CHECKING' &&
        (paySuccessOrSubscribeSuccess ? (
          <Toast visible title={null} showFooter={false} showCloseBtn={false}>
            {productInfo.is_subscription ? (
              <SubscriptionSuccess onOk={closeDialog} />
            ) : (
              productInfo.payCredit && (
                <PayCreditSuccess
                  payCredit={productInfo.payCredit}
                  credit={userInfo.credit}
                  creditPackAmount={userInfo.creditPackAmount}
                  planCreditAmount={userInfo.planCreditAmount}
                  onOk={closeDialog}
                />
              )
            )}
          </Toast>
        ) : (
          <Toast visible title="Falled" showFooter={false}>
            <div className={styles.failToastWrap}>
              Sorry, failed to pay, please try again.
              <Button size="large" onClick={closeDialog}>
                OK
              </Button>
            </div>
          </Toast>
        ))}
    </>
  );
}

function SubscriptionSuccess({onOk}: {onOk: () => void}) {
  return (
    <div className={styles.successToastWrap}>
      <CompleteIcon className={styles.successIcon} />
      <div className={styles.subscriptionSuccessWrap}>
        <div className={styles.title}>Subscription success</div>
        <div className={styles.desc}>
          Thank you for purchasing a subscription to Mootion! We hope you have a
          fantastic time with it. Come to start creating
        </div>
      </div>
      <Button size="large" onClick={onOk}>
        OK
      </Button>
    </div>
  );
}
function PayCreditSuccess({
  payCredit,
  credit,
  creditPackAmount,
  planCreditAmount,
  onOk,
}: {
  payCredit: number;
  credit: number;
  creditPackAmount: number;
  planCreditAmount: number;
  onOk: () => void;
}) {
  return (
    <div className={styles.successToastWrap}>
      <CompleteIcon className={styles.successIcon} />
      <div className={styles.successBuyCreditWrap}>
        <div className={styles.item}>
          <span className={styles.label + ' ' + styles.strong}>
            Pay success
          </span>
          <span className={styles.value + ' ' + styles.strong}>
            <CreditIcon />
            {payCredit}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.label}>Remaining credits </span>
          <span className={styles.value}>
            <CreditIcon />
            {credit + creditPackAmount} / {planCreditAmount}
          </span>
        </div>
      </div>
      <Button size="large" onClick={onOk}>
        OK
      </Button>
    </div>
  );
}
