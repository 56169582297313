// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

type ENV = 'dev' | 'stage' | 'prod';

const GOOGLE_ADS_EVENT_ID_MAP: Record<string, Record<ENV, string>> = {
  subscription: {
    dev: 'BDX3CN29or0ZELqUouUq',
    stage: 'BDX3CN29or0ZELqUouUq',
    prod: 'Eze-CMXHj8wZEOX5wJA-',
  },
};

export function reportSubscriptionToGoogle(orderId: string) {
  // send to Google Ads
  window.gtag('event', 'conversion', {
    send_to: `${process.env.REACT_APP_G_TAG_ADS_ID}/${
      GOOGLE_ADS_EVENT_ID_MAP['subscription'][process.env.REACT_APP_ENV as ENV]
    }`,
    transaction_id: orderId,
  });

  // send to Google Analytics
  window.gtag('event', 'animator_subscription', {
    event_name: 'animator_subscription',
    send_to: process.env.REACT_APP_G_TAG_ANALYTICS_ID,
  });
}
