// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {MessageContextProvider} from 'contexts/MessageContext';
import {MessageBox} from 'pages/components/MessageBox';
import {RouterProvider} from 'react-router-dom';
import {router} from 'router';

export function App() {
  return (
    <MessageContextProvider>
      <MessageBox />
      <RouterProvider router={router} />
    </MessageContextProvider>
  );
}
