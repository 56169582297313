export function getQueryStringParameters(url: string): Record<string, string> {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;
  const paramsObject: Record<string, string> = {};

  // 遍历所有的查询参数，并添加到对象中
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
}
