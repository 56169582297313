// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ComponentType} from 'react';

export function combine<HR extends Record<string, unknown>>(
  useHook: () => HR
): <P>(
  Component: ComponentType<P>
) => (props: Omit<P, keyof HR>) => JSX.Element;

export function combine(
  useHook: () => void
): <P>(Component: ComponentType<P>) => (props: P) => JSX.Element;

export function combine<
  HP extends Record<string, unknown>,
  HR extends Record<string, unknown>
>(
  useHook: (params: HP) => HR,
  paramKeys: (keyof HP)[]
): <P>(
  Component: ComponentType<P>
) => (props: Omit<P, keyof HR> & HP) => JSX.Element;

export function combine<HP extends Record<string, unknown>>(
  useHook: (params: HP) => void,
  paramKeys: (keyof HP)[]
): <P>(Component: ComponentType<P>) => (props: P & HP) => JSX.Element;

export function combine<
  HP extends Record<string, unknown> | never,
  HR extends Record<string, unknown> | void
>(
  useHook: (params: HP) => HR,
  paramKeys?: HP extends never ? never : (keyof HP)[]
) {
  return function <P>(Component: ComponentType<P>) {
    type ComponentProps = JSX.IntrinsicAttributes & P;
    type WrappedComponentProps = (HR extends void ? P : Omit<P, keyof HR>) & HP;

    return function WrappedComponent(props: WrappedComponentProps) {
      const [hookParams, restProps] = !paramKeys
        ? [undefined, props]
        : Object.entries(props).reduce(
            ([hs, rs], [key, value]) =>
              paramKeys.includes(key)
                ? [{...hs, [key]: value}, rs]
                : [hs, {...rs, [key]: value}],
            [{}, {}]
          );
      const hookReturn = useHook(hookParams as HP);
      const combinedProps = {
        ...restProps,
        ...(hookReturn ?? {}),
      } as ComponentProps;
      return <Component {...combinedProps} />;
    };
  };
}
