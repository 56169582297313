// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

import styles from './Popover.module.scss';

interface PopoverProps {
  triggerElement: React.ReactNode;
  content: React.ReactNode;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  bgColor?: string;
  trigger?: 'click' | 'hover';
  key?: string | number;
  children?: React.ReactNode;
  showBorder?: boolean;
  distance?: number;
  delay?: number;
  className?: string;
  disabled?: boolean;
}
export interface PopoverHandle {
  close: () => void;
  clearHoverTimeout: () => void;
}
export const Popover = forwardRef<PopoverHandle, PopoverProps>(
  (props: PopoverProps, ref) => {
    const {
      triggerElement,
      content,
      direction = 'right',
      bgColor = 'white',
      trigger = 'click',
      showBorder = true,
      distance = 0,
      delay = 0,
      className,
      disabled = false,
    } = props;
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({top: 0, left: 0});
    const popoverRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const directionRef = useRef<string>(direction);
    const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const clearHoverTimeout = () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
        hoverTimeoutRef.current = null;
      }
    };

    useImperativeHandle(ref, () => ({
      close() {
        setVisible(false);
      },
      clearHoverTimeout,
    }));

    //popover 的位置计算
    const updatePosition = useCallback(() => {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const popoverRect = popoverRef.current?.getBoundingClientRect();
      if (triggerRect && popoverRect) {
        const {innerWidth, innerHeight} = window;
        const newPosition = {top: 0, left: 0};

        switch (direction) {
          case 'top':
            newPosition.top = triggerRect.top - popoverRect.height + distance;
            newPosition.left =
              triggerRect.left + (triggerRect.width - popoverRect.width) / 2;
            if (newPosition.top < 0) {
              newPosition.top = triggerRect.bottom + distance;
            }
            if (newPosition.top + popoverRect.height > innerHeight) {
              newPosition.top = triggerRect.top - popoverRect.height - distance;
            }
            break;
          case 'right':
            newPosition.left = triggerRect.right + window.scrollX;
            newPosition.top =
              triggerRect.top + (triggerRect.height - popoverRect.height) / 2;
            if (newPosition.left + popoverRect.width > innerWidth) {
              newPosition.left = triggerRect.left - popoverRect.width;
            }
            if (newPosition.top + popoverRect.height > innerHeight) {
              newPosition.top = triggerRect.top - popoverRect.height;
            }
            break;
          case 'bottom':
            newPosition.top = triggerRect.bottom + distance;
            newPosition.left =
              triggerRect.left + (triggerRect.width - popoverRect.width) / 2;
            if (newPosition.top + popoverRect.height > innerHeight) {
              newPosition.top = triggerRect.top - popoverRect.height - distance;
            }
            break;
          case 'left':
            newPosition.left = triggerRect.left - popoverRect.width;
            newPosition.top =
              triggerRect.top + (triggerRect.height - popoverRect.height) / 2;
            if (newPosition.left < 0) {
              newPosition.left = triggerRect.right;
            }
            if (newPosition.top + popoverRect.height < 0) {
              newPosition.top = triggerRect.bottom;
            }
            if (newPosition.top + popoverRect.height > innerHeight) {
              newPosition.top = triggerRect.top - popoverRect.height;
            }
            break;
          default:
            newPosition.left = triggerRect.right + window.scrollX;
            newPosition.top =
              triggerRect.top + (triggerRect.height - popoverRect.height) / 2;
            break;
        }

        setPosition(newPosition);
      }
    }, [direction, distance]);
    useEffect(() => {
      const triggerEl = triggerRef.current;
      const handleHover = () => {
        clearHoverTimeout();
        hoverTimeoutRef.current = setTimeout(() => {
          if (!disabled) setVisible(true);
        }, delay);
      };

      const handleMouseLeave = () => {
        setVisible(false);
        clearHoverTimeout();
      };

      const handleMouseClick = () => {
        setVisible(!visible);
      };
      if (triggerEl) {
        if (trigger === 'hover') {
          triggerEl.addEventListener('mouseenter', handleHover);
          triggerEl.addEventListener('mouseleave', handleMouseLeave);
        } else if (trigger === 'click') {
          triggerEl.addEventListener('click', handleMouseClick);
        }
      }

      return () => {
        if (triggerEl) {
          if (trigger === 'hover') {
            triggerEl.removeEventListener('mouseenter', handleHover);
            triggerEl.removeEventListener('mouseleave', handleMouseLeave);
          } else if (trigger === 'click') {
            triggerEl.removeEventListener('click', handleMouseClick);
          }
        }
      };
    }, [delay, direction, disabled, trigger, visible]); // 依赖 trigger，确保触发方式更新时重新绑定事件

    useEffect(() => {
      if (visible) {
        updatePosition();
      }
      // 页面滚动时调用的函数，用于更新Popover的位置
      const handleScroll = () => {
        if (visible) {
          updatePosition();
        }
      };
      const scrollElement =
        document.getElementById('mootion-aside')?.nextElementSibling;
      // 绑定滚动事件监听器
      scrollElement?.addEventListener('scroll', handleScroll);

      // 组件卸载时移除滚动事件监听器
      return () => {
        scrollElement?.removeEventListener('scroll', handleScroll);
      };
    }, [visible, updatePosition]);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    const popoverStyle = {
      top: `${position.top}px`,
      left: `${position.left}px`,
      '--popover-bgColor': bgColor,
    };
    return (
      <div className={classNames(styles.popoverContainer, [className])}>
        <div ref={triggerRef} className={styles.triggerElement}>
          {triggerElement}
        </div>
        {visible &&
          createPortal(
            <div
              className={classNames(styles.popoverContent, {
                [styles.visible]: visible,
                [styles.showBorder]: showBorder,
              })}
              data-direction={directionRef.current}
              ref={popoverRef}
              style={popoverStyle}
            >
              {content}
            </div>,
            document.body
          )}
      </div>
    );
  }
);
Popover.displayName = 'Popover';
