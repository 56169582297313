// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useEffect} from 'react';

export function NotFoundPage() {
  useEffect(() => {
    window.location.href = `${
      process.env.REACT_APP_HOMEPAGE_URL as string
    }404.html`;
  }, []);
  return null;
}
