// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {UserContextProvider} from 'contexts/UserContext';
import {DiscordCallbackPage} from 'pages/DiscordCallbackPage';
import {NotFoundPage} from 'pages/NotFoundPage';
import {PaymentResult, Plan} from 'pages/PlanPage';
import {createBrowserRouter, Navigate, Outlet} from 'react-router-dom';

const ROOT_PATH = '/';
export const DISCORD_CALLBACK_PATH = '/discord/callback';
export const PLAN_PAGE_PATH = '/plan';
export const PAYMENT_SUCCESS_PATH = `${PLAN_PAGE_PATH}/payment-success`;
export const PAYMENT_CANCEL_PATH = `${PLAN_PAGE_PATH}/payment-cancel`;
export const NOT_FOUND_PAGE_PATH = '/404';

export const router = createBrowserRouter([
  {
    path: ROOT_PATH,
    element: <Outlet />,
    errorElement: <Navigate to={NOT_FOUND_PAGE_PATH} />,
    children: [
      {
        index: true,
        element: <Navigate to={PLAN_PAGE_PATH} />,
      },
      {
        path: DISCORD_CALLBACK_PATH,
        element: <DiscordCallbackPage />,
      },
      {
        path: PLAN_PAGE_PATH,
        element: (
          <UserContextProvider>
            <Plan />
          </UserContextProvider>
        ),
        children: [
          {
            path: PAYMENT_SUCCESS_PATH,
            element: <PaymentResult isSuccess={true} />,
          },
          {
            path: PAYMENT_CANCEL_PATH,
            element: <PaymentResult isSuccess={false} />,
          },
        ],
      },
    ],
  },
  {
    path: NOT_FOUND_PAGE_PATH,
    element: <NotFoundPage />,
  },
]);
