// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {getUserToken} from 'contexts/localStorage';
import {Product} from 'modules/payment/types';

import {ResponseData} from './server';

interface InitiatePrepaymentResponse {
  priceId: string;
  outTradeNo: string;
}

interface SubscriptionDetail {
  creditAmount: number;
  periodEnd: string;
  autoRenewal: 0 | 1;
}

interface UserSubscriptionInfo {
  products: Product[];
  activeProductId: number;
  subscriptionProductId: number;
  subscriptionDetail: SubscriptionDetail | null;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_DISCORD_URL,
  timeout: 10000,
});

api.interceptors.request.use(config => {
  const token = getUserToken();
  token &&
    config.headers &&
    (config.headers['Authorization'] = `Bearer ${token}`);
  return config;
});

api.interceptors.response.use(
  response => {
    return Promise.resolve(response.data);
  },
  error => {
    return Promise.reject(error.response);
  }
);
export async function getUserSubscription(
  userId: string
): Promise<ResponseData<UserSubscriptionInfo>> {
  const response: ResponseData<UserSubscriptionInfo> = await api.get(
    `/order/products/${userId}`
  );
  // Stripe timestamp is in seconds, but we need milliseconds
  if (response.data.subscriptionDetail) {
    response.data.subscriptionDetail.periodEnd = String(
      Number(response.data.subscriptionDetail.periodEnd) * 1000
    );
  }
  return response;
}

export function initiatePrepayment(
  userId: string,
  productId: number
): Promise<ResponseData<InitiatePrepaymentResponse>> {
  return api.post('/order/preorder', {userId, productId});
}

export function checkoutSession({
  price,
  user_id,
  out_trade_no,
  success_url,
  cancel_url,
  is_subscription,
}: {
  price: string;
  user_id: string;
  out_trade_no: string;
  success_url: string;
  cancel_url: string;
  is_subscription: boolean;
}): Promise<ResponseData<string>> {
  return api.post('/order/stripe/checkout/session', {
    line_items: [
      {
        price,
        quantity: 1,
      },
    ],
    payment_method_types: ['card'],
    out_trade_no,
    user_id,
    success_url,
    cancel_url,
    is_subscription,
  });
}

export function checkOrder(outTradeNo: string): Promise<ResponseData<boolean>> {
  return api.get(`/order/${outTradeNo}/success`);
}

export function upgrade(
  curProductId: number,
  newProductId: number,
  userId: string
): Promise<ResponseData<string>> {
  return api.patch('/order/stripe/subscription/upgrade', {
    curProductId,
    newProductId,
    userId,
  });
}

export function downgrade(
  curProductId: number | undefined,
  newProductId: number,
  userId: string
): Promise<Response> {
  return api.patch('/order/stripe/subscription/downgrade', {
    curProductId,
    newProductId,
    userId,
  });
}
