// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DiscordIcon} from 'assets/svg/fill/Discord.svg';
import {ReactComponent as MootionIcon} from 'assets/svg/outline/app-logo.svg';
import {ReactComponent as MootionMiniIcon} from 'assets/svg/outline/app-logo-mini.svg';
import classNames from 'classnames';
import {useUserContext} from 'contexts/UserContext';
import {Button} from 'pages/components/Button';
import {useState} from 'react';

import {subscriptionMap} from '../constData';
import {getLevelByProductId} from '../Plan.container';
import {UserCardPopover} from '../UserCard';
import styles from './Header.module.scss';

export function Header() {
  const [showUserCard, setShowUserCard] = useState(false);
  const {userInfo, activeProductId} = useUserContext();

  return (
    <>
      <div className={classNames('header', styles.container)}>
        <MootionIcon
          className={classNames('cursor-pointer', styles.logo)}
          onClick={() =>
            (window.location.href = process.env
              .REACT_APP_HOMEPAGE_URL as string)
          }
        />
        <MootionMiniIcon
          className={classNames('cursor-pointer', styles.logo_mini)}
          onClick={() =>
            (window.location.href = process.env
              .REACT_APP_HOMEPAGE_URL as string)
          }
        />

        <div className={styles.user_info}>
          <Button
            size="large"
            type="ghost"
            theme="white"
            className={styles.discord_button}
            onClick={() =>
              (location.href = process.env.REACT_APP_DISCORD_JOIN_URL as string)
            }
          >
            <DiscordIcon className={styles.discord_icon} />
            <span className={styles.long}>Create in Discord</span>
            <span className={styles.shot}>Create</span>
          </Button>

          <div className={styles.info}>
            <div className={styles.plan_info}>
              <p className={styles.count}>
                {userInfo.credit + userInfo.creditPackAmount}&nbsp;/&nbsp;
                {userInfo.planCreditAmount}
              </p>
              <p className={styles.level}>
                {' '}
                {subscriptionMap[getLevelByProductId(activeProductId)]}
              </p>
            </div>
            <img
              src={userInfo.portrait}
              className={classNames('cursor-pointer', styles.avatar)}
              onClick={() => setShowUserCard(true)}
            />
          </div>
        </div>
      </div>
      {showUserCard && (
        <UserCardPopover onClose={() => setShowUserCard(false)} />
      )}
    </>
  );
}
