// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as payment from 'api/payment';
import {getUserInfo, UserInfo} from 'api/server';
import Avatar from 'assets/images/avatar.png';
import {ProductIdEnum} from 'modules/payment/types';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {getUserToken} from './localStorage';

interface UserContext {
  isLogin: boolean;
  userInfo: UserInfo;
  activeProductId: number;
  subscriptionProductId: number;
  isLoaded: boolean;

  updateUserInfo: (_newUserInfo: Partial<UserInfo>) => void;
  refreshUserPlan: (_userId: string) => Promise<void>;
  setSubscriptionProductId: (productId: ProductIdEnum) => void;
  setActiveProductId: (_productId: ProductIdEnum) => void;
}

interface Props {
  children: ReactNode;
}

export const initialUserInfo: UserInfo = {
  userId: '',
  userName: '',
  portrait: '',
  email: '',
  expireDate: null,
  autoRenewal: false,
  credit: 0,
  planCreditAmount: 0,
  creditPackAmount: 0,
  renewalDate: '',
};

const DEFAULT_PRODUCT_ID = Infinity;

const userContext = createContext<UserContext>({
  isLogin: false,
  userInfo: initialUserInfo,
  activeProductId: DEFAULT_PRODUCT_ID,
  subscriptionProductId: DEFAULT_PRODUCT_ID,
  isLoaded: true,

  updateUserInfo: (_newUserInfo: Partial<UserInfo>) => undefined,
  refreshUserPlan: async (_userId: string) => undefined,
  setSubscriptionProductId: (_productId: ProductIdEnum) => undefined,
  setActiveProductId: (_productId: ProductIdEnum) => undefined,
});

export function useUserContext() {
  return useContext(userContext);
}

export function UserContextProvider({children}: Props) {
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [subscriptionProductId, setSubscriptionProductId] =
    useState<ProductIdEnum>(DEFAULT_PRODUCT_ID);
  const [activeProductId, setActiveProductId] =
    useState<ProductIdEnum>(DEFAULT_PRODUCT_ID);

  const userAvatar = useMemo(() => {
    if (
      !userInfo.portrait ||
      userInfo.portrait === 'https://res.mootion.ai/share/default_avatar_01.png'
    ) {
      return Avatar;
    } else return userInfo.portrait;
  }, [userInfo.portrait]);

  const updateUserInfo = useCallback((newUserInfo: Partial<UserInfo>) => {
    setUserInfo(userInfo => ({...userInfo, ...newUserInfo}));
  }, []);

  const refreshUserPlan = useCallback(async (userId: string) => {
    const {
      data: {activeProductId, subscriptionProductId},
    } = await payment.getUserSubscription(userId);
    setSubscriptionProductId(subscriptionProductId);
    setActiveProductId(activeProductId);
  }, []);

  useEffect(() => {
    async function initialize() {
      try {
        const token = getUserToken();
        if (!token) throw new Error('No token');

        const userInfo = await getUserInfo();
        updateUserInfo(userInfo);
        await refreshUserPlan(userInfo.userId);
      } catch {
        window.location.href = process.env.REACT_APP_HOMEPAGE_URL as string;
      }
    }
    initialize();
  }, [setUserInfo, refreshUserPlan, updateUserInfo]);

  const contextValue = {
    isLogin: userInfo.userId !== '',
    userInfo: {...userInfo, portrait: userAvatar},
    subscriptionProductId,
    activeProductId,
    isLoaded: activeProductId !== DEFAULT_PRODUCT_ID,

    updateUserInfo,
    refreshUserPlan,
    setSubscriptionProductId,
    setActiveProductId,
  };

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
}
