// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {getUserToken} from 'contexts/localStorage';
import {PlanType} from 'modules/payment/types';

interface Response {
  code: number;
  msg: string;
}

export interface ResponseData<T> extends Response {
  data: T;
}

export interface UserInfo {
  email?: string;
  portrait: string;
  userName: string;
  userId: string;
  // plan: PlanType;
  credit: number;
  creditPackAmount: number;
  planCreditAmount: number;
  expireDate: string | null;
  autoRenewal: boolean;
  renewalDate: string;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

api.interceptors.request.use(config => {
  const token = getUserToken();
  token &&
    config.headers &&
    (config.headers['Authorization'] = `Bearer ${token}`);
  return config;
});

api.interceptors.response.use(
  response => {
    return Promise.resolve(response.data);
  },
  error => {
    return Promise.reject(error.response);
  }
);

export async function getUserInfo(): Promise<UserInfo> {
  const {data} = await api.get('/discord/user/profile');

  // Stripe timestamp is in seconds, but we need milliseconds
  const userinfo: UserInfo = {
    ...data,
    expireDate: data.expireDate ? String(Number(data.expireDate) * 1000) : null,
    autoRenewal: Boolean(data.autoRenewal),
  };
  return userinfo;
}

export function verifyDiscordCode(code: string): Promise<ResponseData<string>> {
  return api.get(`discord/callback_manual?code=${code}`);
}
//=======================account start=======================
export function getAccountDetails(): Promise<GetAccountDetailsResponseType> {
  return api.get('/users/subscriptions/account-details').then(res => res.data);
}
export type GetAccountDetailsResponseType = {
  planType: PlanType;
  planCreditAmount: number;
  creditPackAmount: number;
  creditWalletAmount: number;
};

export type getUserInfoByTokenResponseType = {
  userId: string;
  userName: string;
  portrait: string;
  email: string;
  googleIdentity: boolean;
  emailConfirmStatus: boolean;
};
export function getUserInfoByToken(): Promise<getUserInfoByTokenResponseType> {
  return api.get('/users/me').then(res => res.data);
}

//=======================account end=======================
