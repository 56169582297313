// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {noop} from 'lodash';

import {MaskType, Popover} from '../Popover';
import {Loading} from './Loading';
import {Props} from './Loading.types';

export function PopoverLoading(props: Props) {
  return (
    <Popover onClose={noop} maskType={MaskType.White}>
      <Loading {...props} />
    </Popover>
  );
}
