// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {verifyDiscordCode} from 'api/server';
import {setUserToken} from 'contexts/localStorage';
import {useCallback, useEffect, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {PLAN_PAGE_PATH} from 'router';

export function DiscordCallbackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const initUserToken = useCallback(async () => {
    const code = searchParams.get('code');
    try {
      if (!code) throw new Error('Code is undefined');
      else {
        const token = (await verifyDiscordCode(code)).data;
        setUserToken(token);
        navigate(PLAN_PAGE_PATH);
      }
    } catch {
      window.location.href = process.env.REACT_APP_HOMEPAGE_URL as string;
    }
  }, [navigate, searchParams]);

  const isFirstRef = useRef(true);
  useEffect(() => {
    const isFirst = isFirstRef.current;
    if (isFirst) {
      isFirstRef.current = false;
      initUserToken();
    }
  }, [initUserToken]);

  return null;
}
