// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export type PlanType = 'FREE' | 'STANDARD' | 'PRO';

export type PaymentCycle = 'monthly' | 'yearly';

interface PlanDescription {
  content: string;
  type: 'feature' | 'restriction';
  tips?: string[];
}

export interface Plan {
  type: PlanType;
  price: number;
  totalPrice: number;
  credit: number;
  describes: PlanDescription[];
  productId: ProductIdEnum;
  paymentCycle?: PaymentCycle;
}

export enum ProductIdEnum {
  FREE = 0,
  STANDARD_MONTHLY = 3,
  STANDARD_YEARLY = 4,
  PRO_MONTHLY = 5,
  PRO_YEARLY = 6,
}

export interface Product {
  id: number;
  productName: string;
  planType: PlanType;
  price: string;
  stripeProductId: string;
  creditAmount: number;
}

export enum SubTypeMap {
  'YEAR' = 'yearly',
  'MONTH' = 'monthly',
}
