// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import Cookie from 'js-cookie';

enum LocalStorageKey {
  UserToken = 'ut',
  OutTradeNo = 'otn',
}

enum CookieKey {
  UserToken = 'ut',
}

function cookieDomain() {
  return process.env.REACT_APP_ENV === 'dev'
    ? null
    : process.env.REACT_APP_BASE_DOMAIN;
}

export function setUserToken(token: string) {
  const domain = cookieDomain();
  Cookie.set(CookieKey.UserToken, token, {
    expires: 30,
    ...(domain ? {domain: `.${domain}`} : {}),
  });
}

export function getUserToken() {
  const token = Cookie.get(CookieKey.UserToken);
  if (!token) {
    const t = localStorage.getItem(LocalStorageKey.UserToken);
    if (t) {
      setUserToken(t);
      localStorage.removeItem(LocalStorageKey.UserToken);
    }
    return t;
  }
  return token;
}

export function clearUserToken() {
  const domain = cookieDomain();
  Cookie.remove(CookieKey.UserToken, domain ? {domain: `.${domain}`} : {});
}
export function setOutTradeNo(outTradeNo: string) {
  localStorage.setItem(LocalStorageKey.OutTradeNo, outTradeNo);
}

export function getOutTradeNo() {
  return localStorage.getItem(LocalStorageKey.OutTradeNo);
}

export function clearOutTradeNo() {
  localStorage.removeItem(LocalStorageKey.OutTradeNo);
}
